import React, { useState } from "react";
import "./Leftside.css";
// import video1 from "../../assets/EyeTest-Square-LK@Home.mp4";
import video1 from "../../../../Assets/Video/video.mp4"
import Slider from "react-slick";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowDownShortWide,
  faUpDown,
} from "@fortawesome/free-solid-svg-icons";
import {
  faArrowAltCircleDown,
  faUser,
} from "@fortawesome/free-regular-svg-icons";

const Leftside = () => {
  const [toggle, settoggle] = useState(false);
  const [whichsection, setsection] = useState("");


  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const reviewdata = [
    {
      name: "Neha",
      comment: "Top-notch service, convenient and accurate.",
    },
    {
      name: "Rohan sinha",
      comment: "Quality check-up at home. Highly recommended.",
    },
  ];

  return (
    <div className={`main-div-left-appointment`}>
      <video
        src={video1}
        poster="src/assets/Screenshot 2024-06-18 123012.png"
        autoPlay
        loop
        muted
        className={`videoplayer-appointment`}
      >
        something went wrong
      </video>
      <div className={`div-for-6sign-appointment`}>
        <h2 className={`h2-for-sign-appointment`}>
          <icon>6</icon>
          <p>
            Signs you <br /> need an eyetest
          </p>
        </h2>
      </div>

      <div className={`select-option-section-appointment`}>
        <h2 className={`h2-for-option-appointment`}>FAQs About Home Eye Tests</h2>
        <div>
          <div className={`div-for-select-section-appointment`}>
            <h3
              className={`h3-for-select-appointment`}
              onClick={() => { settoggle(!toggle); setsection("eye-test"); }}
            >
              What is Demo Home Eye Test?
              <icon>
                <FontAwesomeIcon
                  className={`${whichsection == "eye-test" && toggle
                    ? "rotate-icon-appointment"
                    : ""
                    }`}
                  icon={faArrowAltCircleDown}
                />
              </icon>
            </h3>
            <p
              className={`div-for-para-appointment ${whichsection == "eye-test" && toggle ? "show-appointment" : ""
                }`}
            >
              The Lenskart Home Eye Test experience, which brings the complete
              store to your door. Opt for an eye test (performed by a certified
              optometrist) for yourself & your family for only ₹99, a free
              eyewear frame trial, and the option to try and shop from 150+
              eyewear frames. All from the comfort of your home!
            </p>
          </div>


          <div className={`div-for-select-section-appointment`}>
            <h3
              className={`h3-for-select-appointment`}
              onClick={() => {
                settoggle(!toggle);
                setsection("eye-opt");
              }}
            >
              Why Should I Opt for the Lenskart Home Eye Test?
              <icon>
                <FontAwesomeIcon
                  className={`${whichsection == "eye-opt" && toggle ? "rotate-icon-appointment" : ""
                    }`}
                  icon={faArrowAltCircleDown}
                />
              </icon>
            </h3>
            <p
              className={`div-for-para-appointment ${whichsection == "eye-opt" && toggle ? "show-appointment" : ""}`}
            >
              Our home eye check-up offers the following benefits: Safe,
              convenient & economical eye care. Eye tests performed by certified
              optometrists in just 15 minutes. Use of state-of-the-art testing
              equipment. 12-step eye test for accurate prescription. A great
              shopping experience-try & buy from 150+ eyewear styles.
            </p>
          </div>

          {/* cities should service available */}
          <div className={`div-for-select-section-appointment`}>
            <h3
              className={`h3-for-select-appointment`}
              onClick={() => { settoggle(!toggle); setsection("eye-available-in"); }}
            >
              What Cities Is this Service Available In?
              <icon>
                <FontAwesomeIcon
                  className={`${whichsection == "eye-available-in" && toggle
                    ? "rotate-icon-appointment"
                    : ""
                    }`}
                  icon={faArrowAltCircleDown}
                />
              </icon>
            </h3>
            <p
              className={`div-for-para-appointment ${whichsection == "eye-available-in" && toggle ? "show-appointment" : ""
                }`}
            >
              We are currently serviceable in Delhi NCR, Bangalore, Mumbai,
              Kolkata, Hyderabad, Pune, Ahmedabad, Durgapur, Mysore, Coimbatore,
              Hubli, Pondicherry, Visakhapatnam, Chennai, Patna, Madurai,
              Guwahati, Bhubaneswar, Nagpur, Ranchi, Kochi, Jaipur, Chandigarh,
              Lucknow, Agra, Indore, Amritsar, Dehradun, Kanpur, Bhopal,
              Ludhiana, Solapur, Prayagraj, Jammu.
            </p>
          </div>

          {/* eye check up */}
          <div className={`div-for-select-section-appointment`}>
            <h3
              className={`h3-for-select-appointment`}
              onClick={() => {
                settoggle(!toggle);
                setsection("eye-check-up");
              }}
            >
              How Much Does the Lenskart Eye Check Up Cost?
              <icon>
                <FontAwesomeIcon
                  className={`${whichsection == "eye-check-up" && toggle
                    ? "rotate-icon-appointment"
                    : ""
                    }`}
                  icon={faArrowAltCircleDown}
                />
              </icon>
            </h3>
            <p
              className={`div-for-para-appointment ${whichsection == "eye-check-up" && toggle ? "show-appointment" : ""}`}
            >
              For a basic eye check-up, there's no need to go anywhere. Lenskart
              offers the convenient HTO Service, allowing you to undergo your
              eye test in the comfort of your own home.
            </p>
          </div>

          {/* eye check cost */}
          <div className={`div-for-select-section--appointment`}>
            <h3
              className={`h3-for-select-appointment`}
              onClick={() => {
                settoggle(!toggle);
                setsection("eye-cost");
              }}
            >
              How Much Does the Lenskart Eye Check Up Cost?
              <icon>
                <FontAwesomeIcon
                  className={`${whichsection == "eye-cost" && toggle ? "rotate-icon-appointment" : ""
                    }`}
                  icon={faArrowAltCircleDown}
                />
              </icon>
            </h3>
            <p
              className={`div-for-para-appointment ${whichsection == "eye-cost" && toggle ? "show-appointment" : ""
                }`}
            >
              You can book an eye test for only ₹99, which is free for Gold
              members.
            </p>
          </div>

          {/* power at home */}
          <div className={`div-for-select-section-appointment`}>
            <h3
              className={`h3-for-select-appointment`}
              onClick={() => {
                settoggle(!toggle);
                setsection("eye-power-home");
              }}
            >
              How to Test Eye Power at Home?
              <icon>
                <FontAwesomeIcon
                  className={`${whichsection == "eye-power-home" && toggle ? "rotate-icon-appointment" : ""}`}
                  icon={faArrowAltCircleDown}
                />
              </icon>
            </h3>
            <p
              className={`div-for-para-appointment ${whichsection == "eye-power-home" && toggle ? "show-appointment" : ""
                }`}
            >
              You can conduct a basic test for your eyesight at home using a
              Snellen chart, which displays letters in various sizes. Stand
              about 10 feet away, cover one eye, and read the smallest line you
              can clearly see. Repeat with the other eye. Remember, this test
              doesn't replace a comprehensive eye exam by an optometrist or
              ophthalmologist for accurate assessment and prescription. This is
              where Lenskart comes to your rescue. Lenskart’s Home Eye Test is
              conducted by professional Optometrists at the convenience of your
              home.
            </p>
          </div>

          {/* eyesight at home */}
          <div className={`div-for-select-section-appointment`}>
            <h3
              className={`h3-for-select-appointment`}
              onClick={() => {
                settoggle(!toggle);
                setsection("ayesight-at-home");
              }}
            >
              How to Check Your Eyesight at Home?
              <icon>
                <FontAwesomeIcon
                  className={`${whichsection == "ayesight-at-home" && toggle
                    ? "rotate-icon-appointment"
                    : ""
                    }`}
                  icon={faArrowAltCircleDown}
                />
              </icon>
            </h3>
            <p
              className={`div-for-para-appointment ${whichsection == "ayesight-at-home" && toggle ? "show-appointment" : ""
                }`}
            >
              Checking your eyesight at home can be done using a Snellen chart,
              but there are other methods you can try as well, such as an Amsler
              grid, a near vision test with reading material, or a visual acuity
              test using smartphone apps. However, for a comprehensive and
              precise evaluation, Lenskart's Home Eye Test remains an ideal
              solution. Our certified optometrists bring advanced tools to your
              doorstep, conducting a thorough eye test within 5 minutes at a
              nominal cost of ₹99, ensuring accurate results in the comfort of
              your home.
            </p>
          </div>

          {/* number at home */}
          <div className={`div-for-select-section-appointment`}>
            <h3
              className={`h3-for-select-appointment`}
              onClick={() => {
                settoggle(!toggle);
                setsection("eye-number-home");
              }}
            >
              How to Check the Eye Number at Home?
              <icon>
                <FontAwesomeIcon
                  className={`${whichsection == "eye-number-home" && toggle
                    ? "rotate-icon-appointment"
                    : ""
                    }`}
                  icon={faArrowAltCircleDown}
                />
              </icon>
            </h3>
            <p
              className={`div-for-para-appointment ${whichsection == "eye-number-home" && toggle ? "show-appointment" : ""
                }`}
            >
              Checking your eye number at home can be challenging without proper
              tools. However, you can use a basic method by covering one eye and
              focusing on an object at a distance, then repeating with the other
              eye. If objects appear blurry, it might indicate a vision issue.
              For a precise evaluation, visiting an optometrist or using
              services like Lenskart's Home Eye Test, which conducts
              comprehensive eye exams at home for a minimal cost, is recommended
              for accurate assessment of your eye number.
            </p>
          </div>
        </div>
      </div>

      {/* need for help */}

      <div className={`need-for-help-appointment`}>
        <p className={`need-p-appointment`}>Need more help</p>
        <a className={`a-help-appointment`}>Chat with us</a>
      </div>

      {/* review box */}
      <div className={`review-box-appointment`}>
        <h3 className={`h3-for-r-box-appointment`}>Rating & Reviews</h3>
        {reviewdata.map((item) => {
          return (
            <>
              <div className={`comment-box-appointment`}>
                {/* top */}
                <div className={`comment-top-appointment`}>
                  <span className="appointment-span">
                    <FontAwesomeIcon icon={faUser} />
                  </span>
                  <p className={`p-name-appointment`}>{item.name}</p>
                </div>

                {/* bottom */}
                <p>{item.comment}</p>
              </div>
            </>
          );
        })}
      </div>
    </div>
  );
};

export default Leftside;
